import loadable from "@loadable/component";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";
import { MetaComponent } from "../components";
import { InsNeedsContainer, InsuranceNeedsCard } from "../components/campaigns/google1/WhyChooseVerak";
import { Button, HeaderBG, SassyText, Section, SectionWrapper, StyledLink, Text, Title } from "../components/core";
import { SubTitle } from "../components/core/commonExports";
import { OnDesktop, OnMobile } from "../components/DesktopMobileComps";
import { useMixpanel } from "../context/MixpanelContext";
import useHindiLangFont from "../hooks/useHindiLangFont";
import { noOfBusinesses } from "../utils/variables";
import { LeadFormProvider } from "../context/LeadFormContext";

const Footer = loadable(() => import("../components"), {
    resolveComponent: (components) => components.Footer,
});
const LeadForm = loadable(() => import("../components/LeadForm"));
const Navbar = loadable(() => import("../components"), {
    resolveComponent: (components) => components.Navbar,
});

const OurCompany = () => {
    const {t} = useTranslation(['our-company', 'common'])
    const { hindiBold, hindiRegular } = useHindiLangFont()
    const mixpanel = useMixpanel()
    const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

    const handleQuoteModalOpen = () => {
        setIsQuoteModalOpen(true);
    };

    const handleQuoteModalClose = () => {
        setIsQuoteModalOpen(false);
    };

    const handleGetQuoteClick = (e, mxEventName = "") => {
        if (mxEventName) {mixpanel.track(mxEventName)}
        handleQuoteModalOpen()
    }

    return (
        <>
            <LeadFormProvider>
                {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
            </LeadFormProvider>
            <MetaComponent
                title="Our Company - Verak"
                description="Verak's flagship Vyapar Suraksha policy covers businesses of against 15+ risks including fire, theft and flood. We aim to insure 10 Lakh businesses in India by 2027."
                url="aboutus"
            />
            <Navbar active="aboutus" />
            <Section background="#ffffff" style={{marginTop: "80px"}}>
                <OnDesktop><HeaderBG fullBg imgStyle={{height: "580px"}} /></OnDesktop>
                <OnMobile><HeaderBG fullBg imgStyle={{height: "800px"}} /></OnMobile>
                <SectionWrapper>
                    <Title className={hindiBold} fontSize="58px" mfontSize="32px" lineHeight="70px" mlineHeight="38px" style={{textAlign: "left"}}>
                        <SassyText>{t('Title')}</SassyText>
                    </Title>
                    <OurCompanySubHeader>
                        <IndiaMapContainer>
                            <StaticImage 
                                src="../assets/images/india-map.webp"
                                alt="insure businesses across india"
                                width={256}
                                height={285}
                                loading="lazy"
                            />
                        </IndiaMapContainer>
                        <Text className={hindiBold} fontSize="30px" mfontSize="18px" fontWeight="700" lineHeight="51px" mlineHeight="30.6px" style={{textAlign: "left"}}>
                            <Trans i18nKey="Subtitle">Verak aims to insure <SassyText>10 Lakh businesses</SassyText> in India by 31st March 2027</Trans>
                        </Text>
                    </OurCompanySubHeader>
                </SectionWrapper>
            </Section>
            <Section background="#fff">
                {/* TODO: add autoplaying customer images */}
                <SectionWrapper>
                    <SubTitle fontSize="36px" mfontSize="24px" lineHeight="43px" mlineHeight="28.8px">
                        <SassyText>{t('OurCustomersSection.Title')}</SassyText>
                    </SubTitle>
                    <OurCustomersInfo>
                        <SweetShopImgContainer>
                            <StaticImage 
                                src="../assets/images/sweets-shop.webp"
                                alt="sweet shop"
                                width={442}
                                height={260}
                                loading="lazy"
                            />
                        </SweetShopImgContainer>
                        <div style={{maxWidth: "610px", textAlign: "left"}}>
                            <Text fontWeight="bold" fontSize="25.5px" mfontSize="18px" lineHeight="38.8px" mlineHeight="28.8px" color="rgba(51, 51, 51, 1)">
                                {t('OurCustomersSection.Desc1')}
                            </Text>
                            <Text fontSize="19px" mfontSize="14px" lineHeight="28.8px" mlineHeight="22px" color="rgba(76, 76, 76, 1)" style={{marginTop: "20px"}}>
                                {t('OurCustomersSection.Desc2')}
                            </Text>
                        </div>
                    </OurCustomersInfo>
                    <StyledLink to="/our-customers">
                        <OnDesktop>
                            <Button
                                label={t('OurCustomersSection.DesktopCTA')}
                                wide
                                type="secondary"
                                // onClick={() => mixpanel.track("Clicked 'Learn more about us'")}
                                // className={hindiBold}
                                style={{margin: "auto"}}
                            />
                        </OnDesktop>
                        <OnMobile>
                            <Button
                                label={t('OurCustomersSection.MobileCTA')}
                                wide
                                type="secondary"
                                // onClick={() => mixpanel.track("Clicked 'Learn more about us'")}
                                // className={hindiBold}
                                style={{margin: "auto"}}
                            />
                        </OnMobile>
                    </StyledLink>
                </SectionWrapper>
            </Section>
            <Section background="#ffffff" style={{marginTop: "75px"}}>
                <SectionWrapper>
                    <SubTitle fontSize="36px" mfontSize="24px" lineHeight="43.3px" mlineHeight="28.8px" desktopStyles={{textAlign: "left"}} mobileStyles={{marginBottom: '16px'}}>
                        <SassyText>{t("PlatformBenefits.Title")}</SassyText>
                    </SubTitle>
                    <InsNeedsContainer>
                        <InsuranceNeedsCard icon="Instant Quotes" title={t("PlatformBenefits.InstantQuotes.Title")} desc={t("PlatformBenefits.InstantQuotes.Desc")} />
                        <InsuranceNeedsCard icon="Whatsapp Support" title={t("PlatformBenefits.WhatsappSupport.Title")} desc={t("PlatformBenefits.WhatsappSupport.Desc")} />
                        <InsuranceNeedsCard icon="Complete Guidance" title={t("PlatformBenefits.CompleteGuidance.Title")} desc={t("PlatformBenefits.CompleteGuidance.Desc")} />
                    </InsNeedsContainer>
                    <OnDesktop><Button wide label={t('CTALabels.GetAQuote')} style={{margin: "auto", marginTop: "98px"}} onClick={handleGetQuoteClick} /></OnDesktop>
                    <OnMobile><Button wide label={t('CTALabels.GetAQuote')} style={{margin: "auto", marginTop: "36px", fontSize: "18px"}} onClick={handleGetQuoteClick} /></OnMobile>
                </SectionWrapper>
            </Section>
            <Section background="rgba(255, 242, 234, 1)" style={{marginTop: "75px"}}>
                <SectionWrapper>
                    <StatsContainer>
                        <StatItem>
                            <Text fontSize="64px" mfontSize="46px" color="rgba(3, 4, 94, 1)" fontWeight="bold" lineHeight="80px" mlineHeight="58px">
                                {noOfBusinesses}
                            </Text>
                            <Text fontSize="20px" mfontSize="16px" style={{marginTop: "27px", lineHeight: "30px"}} className={hindiRegular}>
                                <Trans i18nKey="Stats.ClientsAcrossIndia"><strong>Clients spread across India</strong> from businesses to big firms.</Trans>
                            </Text>
                        </StatItem>
                        <StatItem>
                            <Text fontSize="64px" mfontSize="46px" color="rgba(3, 4, 94, 1)" fontWeight="bold" lineHeight="80px" mlineHeight="58px">
                                5+
                            </Text>
                            <Text fontSize="20px" mfontSize="16px" style={{marginTop: "27px", lineHeight: "30px"}} className={hindiRegular}>
                                <Trans i18nKey="Stats.YearsOfExp"><strong>Years of experience</strong> in the commercial insurance domain.</Trans>
                            </Text>
                        </StatItem>
                        <StatItem>
                            <Text fontSize="64px" mfontSize="46px" color="rgba(3, 4, 94, 1)" fontWeight="bold" lineHeight="80px" mlineHeight="58px">
                                10+
                            </Text>
                            <Text fontSize="20px" mfontSize="16px" style={{marginTop: "27px", lineHeight: "30px"}} className={hindiRegular}>
                                <Trans i18nKey="Stats.ReputedPartners"><strong>Reputed insurance partners</strong> including Oriental, New India and ICICI.</Trans>
                            </Text>
                        </StatItem>
                    </StatsContainer>
                </SectionWrapper>
            </Section>
            <Section background="#ffffff" className="position-relative">
                <div className="on-desktop" style={{position: 'absolute', background: 'rgba(255, 247, 241, 0.6)', right: '0', top: '4rem', bottom: "4rem", width: '50%'}}>
                </div>
                <SectionWrapper>
                    <ClosingSectionContainer className="position-relative">
                        <ClosingSectionHeaderContainer>
                            <SubTitle fontSize="36px" mfontSize="24px" lineHeight="56px" mlineHeight="36px" style={{ marginBottom: '40px', textAlign: "left" }} className={hindiBold}>
                                <Trans i18nKey="ClosingSectionText">Protect your shop, restaurant, godown or factory from 15+ threats including theft, fire, flood and riots <br/><SassyText>starting at just ₹8 per day.</SassyText></Trans>
                            </SubTitle>
                            <div className="on-desktop">
                                <Button wide label={t('CTALabels.GetAQuote')} onClick={handleGetQuoteClick} />
                            </div>
                        </ClosingSectionHeaderContainer>
                        <ClosingSectionImgWrapper>
                            <StaticImage
                                src="../assets/images/yogesh_autoparts_art.webp"
                                alt="secure your business"
                                width={423}
                                height={473}
                            />
                        </ClosingSectionImgWrapper>
                        <div className="on-mobile">
                            <Button wide label={t('CTALabels.GetAQuote')} style={{margin: 'auto', marginTop: '2.5rem', width: "min(250px, 100%)"}} onClick={handleGetQuoteClick} />
                        </div>
                    </ClosingSectionContainer>
                </SectionWrapper>
            </Section>
            <Footer />
        </>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["our-company", "common", "navbar", "footer", "getQuoteModal"]
        }, 
        language: {eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const IndiaMapContainer = styled.div`
    width: min(256px, 100%);
    min-width: 200px;
`

const OurCompanySubHeader = styled.div`
    display: flex; 
    justify-content: space-between; 
    margin-top: 48px; 
    gap: 80px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 32px;
        margin-bottom: 100px;
    }
`

const OurCustomersInfo = styled(OurCompanySubHeader)`
    gap: 0px;
    justify-content: space-around; 
    margin-bottom: 80px;
    @media screen and (max-width: 768px) {
        margin-bottom: 40px;
    }
`

const SweetShopImgContainer = styled.div`
    width: min(442px, 100%);
    min-width: 250px;
`

const StatsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: fit-content;
    margin: auto;
    gap: 80px;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 45px;
    }
`

const StatItem = styled.div`
    text-align: center;
    max-width: 320px;
`

const ClosingSectionHeaderContainer = styled.div`
  margin-left: 0; 
  max-width: 540px;
  width: 100%;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const ClosingSectionContainer = styled.div`
  margin: 50px 0px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
  }
`;

const ClosingSectionImgWrapper = styled.div`
    width: min(423px, 100%);
    @media screen and (max-width: 768px) {
        padding: 1rem 1.5rem;
        background: rgba(255, 247, 241, 0.6);  
    }
`

export default OurCompany