import React from "react"
import styled from "styled-components"
import { SassyText, Section, SectionWrapper, Text, Title } from "../../core/commonExports"
import { Button } from "../../core/Button"
import { OnDesktop, OnMobile } from "../../DesktopMobileComps"

import InstantQuotes from "../../../assets/images/instantQuotes.svg"
import WASupport from "../../../assets/images/whatsappSupport.svg"
import CompleteGuidance from "../../../assets/images/completeGuidance.svg"

export const InsuranceNeedsCard = ({title, desc, icon}) => {
    let iconStyles = {borderRadius: "4px"}
    const getIcon = () => {
        switch (icon || title) {
            case "Instant Quotes": 
                return <InstantQuotes style={iconStyles} />
            case "Whatsapp Support": 
                return <WASupport style={iconStyles} />
            case "Complete Guidance":
                return <CompleteGuidance style={iconStyles} />
            default:
                return;
        }
    }
    return (
        <InsNeedsCard>
            <InsNeedsIconContainer>
                {getIcon()}
            </InsNeedsIconContainer>
            <Text fontSize="24px" mfontSize="20px" fontWeight="bold" style={{lineHeight: "60px"}}>
                {title}
            </Text>
            <Text fontSize="16px" mfontSize="14px" fontWeight="500" style={{lineHeight: "30px"}}>
                {desc}
            </Text>
        </InsNeedsCard>
    )
}

const WhyChooseVerak = ({handleGetQuoteClick, lpName="GLP2", hideQuoteCTA = false}) => {
    return (
        <Section background="#ffffff">
            <SectionWrapper>
                <Title fontSize="36px" mfontSize="24px" desktopStyles={{textAlign: "center"}} mobileStyles={{marginBottom: '16px'}}>
                    {lpName === "GLP2" 
                        ? "Why choose Verak for your insurance needs?"
                        : <SassyText>Why choose Verak</SassyText>
                    }
                </Title>
                <InsNeedsContainer>
                    <InsuranceNeedsCard title="Instant Quotes" desc="Get instant quotes from reputed insurers like Oriental Insurance, New India Assurance and many more" />
                    <InsuranceNeedsCard title="Whatsapp Support" desc="Instant support and paperless claims on WhatsApp" />
                    <InsuranceNeedsCard title="Complete Guidance" desc="Complete guidance on selecting the right insurance policy for your business" />
                </InsNeedsContainer>
                {!hideQuoteCTA && (
                    <>
                        <OnDesktop><Button wide label="GET A QUOTE" style={{margin: "auto", marginTop: "98px"}} onClick={handleGetQuoteClick} /></OnDesktop>
                        <OnMobile><Button wide label="GET A QUOTE" style={{margin: "auto", marginTop: "36px", fontSize: "18px"}} onClick={handleGetQuoteClick} /></OnMobile>
                    </>
                )}
            </SectionWrapper>
        </Section>
    )
}

const InsNeedsCard = styled.article`
    position: relative;
    padding: 40px 58px;
    width: 410px;
    background: #FFFFFF;
    border: 0.5px solid rgba(139, 142, 209, 0.5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    text-align: center;
    @media (max-width: 768px) {
        width: 100%;
        padding: 1rem 1.5rem;
        margin-bottom: 1.5rem;
    }
`

const InsNeedsIconContainer = styled.div`
    position: absolute; 
    top: -25px; 
    left: 0; 
    right: 0;
    border-radius: 4px;
`

export const InsNeedsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 100px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin-top: 70px;
    }
`

export default WhyChooseVerak